<template>
  <div class="share-container p-s16 md:my-s8">
    <div class="headline-large">
      {{$t('share.share_this_link')}}
    </div>

    <!-- Url / Copy -->
    <div class="mt-s24 flex justify-center items-start w-full">
      <CustomInput
        ref="shareUrl"
        :value="shareUrl"
        class="w-full"
        type="text"
        :disabled="true"
        :data-lpignore="true"
        testId="share-url"
      />
      <ButtonV2
        class="mt-s2 ml-s16"
        @onClick="copy"
        :label="$tc('share.copy')"
        testId="btn-copy"
        size="medium"
      />
    </div>
    <div v-show="copied" class="body-text-medium">
      <p>
        <font-awesome-icon icon="check-circle" class="text-text-active-2 mr-s4" />
        {{ $t('link_copied') }}
      </p>
    </div>

    <!-- Sharing options -->
    <LineSeparator v-if="showFacebook || showTwitter || showEmail" :label="$tc('or')"/>
    <ButtonV2
      class="mt-s12"
      v-if="showFacebook"
      @onClick="shareExternal(`https://www.facebook.com/dialog/feed?&app_id=611965336396853&link=${shareUrl}&quote=${encodeURI(shareMessage)}`)"
      :label="$tc('share.share_facebook')"
      testId="btn-share-fb"
      version="secondary"
      size="medium"
      icon="facebook"
      icon-style="fab"
      wide
    />
    <ButtonV2
      class="mt-s16"
      v-if="showTwitter"
      @onClick="shareExternal(`https://twitter.com/intent/tweet?text=${encodeURI(shareMessage)}&url=${shareUrl}`)"
      :label="$tc('share.share_twitter')"
      testId="btn-share-twitter"
      version="secondary"
      size="medium"
      icon="twitter"
      icon-style="fab"
      wide
    />
    <ButtonV2
      class="mt-s16"
      v-if="showEmail"
      @onClick="shareExternal(`mailto:?subject=${encodeURI(shareTitle)}&body=${encodeURI(shareMessage)}%20${shareUrl}`)"
      :label="$tc('share.share_email')"
      testId="btn-share-email"
      version="secondary"
      size="medium"
      icon="envelope"
      icon-style="fas"
      wide
    />
  </div>
</template>

<script>
import { CustomInput, ButtonV2, LineSeparator } from '@/components/misc';

export default {
  name: 'Share',
  components: {
    ButtonV2,
    CustomInput,
    LineSeparator
  },

  data() {
    return {
      copied: false,
    };
  },

  computed: {
    messageAndUrl() {
      return `${this.shareMessage} ${this.shareUrl}`;
    }
  },

  props: {
    shareUrl: {
      type: String,
      required: true,
    },

    shareTitle: {
      type: String,
      required: true,
    },

    shareMessage: {
      type: String,
      required: true,
    },

    showEmail: {
      type: Boolean,
      default: true,
    },

    showFacebook: {
      type: Boolean,
      default: true,
    },

    showTwitter: {
      type: Boolean,
      default: true,
    }
  },

  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.messageAndUrl);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 6000);
        this.$emit('shareCopy');
      } catch(err) {
        this.copied = false;
        this.hideModal();
        await this.showError(err);
      }
    },

    shareExternal(url, medium) {
      this.goToExternalPageGlobal(url);
      this.$emit('shareExternal', medium);
    }
  },
};
</script>

<style scoped>
  .share-container {
    @apply flex flex-col justify-center items-center w-full;
    max-width: 400px;
  }
</style>
